






import { ParsingError } from "@/utilities/Parser"
import { computed, defineComponent } from "@vue/composition-api"
export default defineComponent({
    name: "ParsedErrorSpan",
    props: {
        value: {
            type: Array as () => ParsingError[],
        },
        sheet: String,
        col: String,
    },
    setup(props) {
        const filteredErrors = computed(() =>
            (props.value ?? []).filter(
                (e) =>
                    (!props.sheet || e.sheet === props.sheet) &&
                    (!props.col || e.col === props.col)
            )
        )
        const errorText = computed(() =>
            filteredErrors.value.map((err) => err.err).join(", ")
        )

        return {
            filteredErrors,
            errorText,
        }
    },
})
