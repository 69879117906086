






















































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import ParsedMissionFieldTable from "./ParsedMissionFieldTable.vue"
import UserChips from "@/components/UserChips.vue"
import { title } from "@/services/AppBarService"
import {
    getMissionTemplate,
    MissionFieldTemplateDTO,
    MissionTemplateViewModel,
} from "@/services/MissionTemplatesService"
import { missionTypeFormatter } from "@/services/MissionsService"
import { userCache } from "@/services/UsersClient"
import {
    SpaceObjectCache,
    spaceObjectFormatter,
} from "@/services/SpaceObjectsService"

export default defineComponent({
    name: "MissionTemplateDetail",
    components: {
        UserChips,
        ParsedMissionFieldTable,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        title.value = `任務範本檢視：${props.id}`
        const state = reactive({
            missionTemplate: null as MissionTemplateViewModel | null,
            missionFieldTemplates: null as
                | (MissionFieldTemplateDTO & {
                      spaceObject?: string | null
                  })[]
                | null,
        })

        async function load() {
            const missionTemplate: MissionTemplateViewModel =
                await getMissionTemplate(props.id)
            missionTemplate.managers = undefined
            missionTemplate.assignees = undefined
            missionTemplate.cooperators = undefined
            state.missionTemplate = missionTemplate
            title.value = `任務範本檢視：${missionTemplate.subject}`

            await userCache.updateByIds(
                missionTemplate.managerIds
                    .concat(missionTemplate.assigneeIds)
                    .concat(missionTemplate.cooperatorIds)
            )

            missionTemplate.managers = missionTemplate.managerIds.map(
                (id) => userCache.get(id) ?? null
            )
            missionTemplate.assignees = missionTemplate.assigneeIds.map(
                (id) => userCache.get(id) ?? null
            )
            missionTemplate.cooperators = missionTemplate.cooperatorIds.map(
                (id) => userCache.get(id) ?? null
            )

            const fieldTemplates = missionTemplate.missionFieldTemplates!.map(
                (a) => {
                    const result: MissionFieldTemplateDTO & {
                        spaceObject?: string | null
                    } = a
                    result.spaceObject = ""
                    return result
                }
            )

            await SpaceObjectCache.updateByIds(
                fieldTemplates
                    .map((field) => field.spaceObjectId)
                    .filter((id) => !!id) as string[]
            )

            fieldTemplates.forEach((item) => {
                if (!item.spaceObjectId) return
                const found = SpaceObjectCache.get(item.spaceObjectId)
                item.spaceObject = spaceObjectFormatter(found)
            })

            state.missionFieldTemplates = fieldTemplates
        }

        load()

        return {
            ...toRefs(state),
            missionTypeFormatter,
        }
    },
})
