











import { defineComponent } from "@vue/composition-api"
export default defineComponent({
    name: "ChipWithTooltip",
    props: {
        label: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
    },
    setup(_, { emit }) {
        function click() {
            emit("click")
        }
        return {
            click,
        }
    },
})
