
















































import { defineComponent, PropType } from "@vue/composition-api"
import { ParsedMissionFieldTemplate } from "@/utilities/Parser"
import ParsedErrorSpan from "@/components/ParsedErrorSpan.vue"
import { fieldTypeFormatter } from "@/services/MissionTemplatesService"
import { toJSON } from "@/utilities/Formatter"

export default defineComponent({
    name: "MissionFieldTable",
    components: {
        ParsedErrorSpan,
    },
    props: {
        value: {
            required: true,
            type: Array as PropType<ParsedMissionFieldTemplate[]>,
        },
    },
    setup() {
        const headers = [
            {
                text: "列",
                sortable: false,
            },
            {
                text: "任務範本ID",
                sortable: false,
            },
            {
                text: "工項順序",
                sortable: false,
            },
            {
                text: "空間物件ID",
                sortable: false,
            },
            {
                text: "空間物件對照",
                sortable: false,
            },
            {
                text: "主旨",
                sortable: false,
            },
            {
                text: "描述",
                sortable: false,
            },
            {
                text: "工項類型",
                sortable: false,
            },
            {
                text: "選擇選項",
                sortable: false,
            },
            {
                text: "預設值",
                sortable: false,
            },
        ]
        return {
            headers,
            fieldTypeFormatter,
            toJSON,
        }
    },
})
