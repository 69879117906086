








import { UserDTO } from "@/services/UsersClient"
import { defineComponent } from "@vue/composition-api"
import UserChip from "./UserChip.vue"
export default defineComponent({
    name: "UserChips",
    components: {
        UserChip,
    },
    props: {
        users: {
            type: Array as () => UserDTO[],
        },
    },
})
