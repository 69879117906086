






import { userCache, UserDTO } from "@/services/UsersClient"
import {
    computed,
    defineComponent,
    reactive,
    watch,
} from "@vue/composition-api"
import ChipWithTooltip from "./ChipWithTooltip.vue"
export default defineComponent({
    name: "UserChip",
    components: {
        ChipWithTooltip,
    },
    props: {
        user: {
            type: Object as () => UserDTO,
        },
        userId: {
            type: String,
        },
    },
    setup(props) {
        const state = reactive({
            stateUser: null as UserDTO | null,
        })

        const displayUser = computed(() => props.user ?? state.stateUser)

        watch(
            () => props.userId,
            async (id) => {
                if (!id) return
                await userCache.updateByIds([id])
                state.stateUser = userCache.get(id) ?? null
            },
            {
                immediate: true,
            }
        )

        return {
            displayUser,
        }
    },
})
